import axios from '@/axios'
const route = 'system/role' 
export default{
    getAll(ItemsPerPage,page,search){
        return  axios.get(route+'?page='+page+'&items_per_page='+ItemsPerPage+'&search='+search)
    },

    store(role){
        return axios.post(route,role)
    },

    update(role){
        return axios.put(route + '/' + role.id ,role)
    },

    destroy(role){
        return axios.delete(route+ '/' + role.id)
    },

}
